<template>
  <div class="container">
    <a-button
      @click="$router.back()"
      style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;margin-bottom: 10px;"
      ><LeftOutlined /> Back</a-button
    >
    <div class="doctor-prescription  p-sm-5">
      <h1 class="title mb-4">RESEP DOKTER</h1>
      <!-- Added form for detailed information -->
      <a-form :model="profileData" class="p-1 p-sm-5">
        <div class="row form-item">
          <div class="col-12 col-sm-2 text-left text-sm-right"><label>Nama Dokter :</label></div>
          <div class="col-12 col-sm-10"><a-input v-model:value="profileData.namaDokter" /></div>
        </div>
        <div class="row form-item">
          <div class="col-12 col-sm-2 text-left text-sm-right"><label>Jenis Terapi :</label></div>
          <div class="col-12 col-sm-10"><a-input v-model:value="profileData.jenisTerapi" /></div>
        </div>
        <div class="row form-item mt-4">
          <div class="col-12 col-sm-2 text-left text-sm-right">
            <label>Unggah Resep Dokter :</label>
          </div>
          <div class="col-12 col-sm-10">
            <p class="text-left m-0 mb-3">
              Pastikan anda mengunggah foto resep secara lengkap dan jelas
            </p>
            <a-upload
              class="upload-section"
              list-type="picture-card"
              :file-list="fileList"
              @change="handleChange"
              @preview="handlePreview"
              :before-upload="() => false"
              :max-count="1"
            >
              <div v-if="!fileList.length">
                <a-icon type="camera" />
                <div>Unggah Foto</div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" centered>
              <img
                alt="example"
                style="width: 100%;margin-top: 30px; object-fit: cover;"
                :src="previewImage"
              />
            </a-modal>
          </div>
        </div>
        <div class="row form-item mt-4" v-if="profileData.jenisTerapi === 'ENHERTU'">
          <div class="col-12 col-sm-2 text-left text-sm-right">
            <label>Unggah Berat Badan :</label>
          </div>
          <div class="col-12 col-sm-10">
            <p class="text-left m-0 mb-3">
              Pastikan anda mengunggah foto berat badan secara lengkap dan jelas
            </p>
            <a-upload
              class="upload-section"
              list-type="picture-card"
              :file-list="fileListWeight"
              @change="handleChangeWeight"
              :before-upload="() => false"
            >
              <div>
                <a-icon type="camera" />
                <div>Unggah Foto</div>
              </div>
            </a-upload>
          </div>
        </div>
        <a-button type="primary" @click="handleSubmit">KIRIM</a-button>
      </a-form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { Upload, Form, Input, Button } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  getPatientProgram,
  uploadResepImage,
  uploadResepResult,
  uploadWeightImage,
} from '../../../services/axios/pulih'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  ContactsOutlined,
  ExclamationCircleTwoTone,
  LeftOutlined,
} from '@ant-design/icons-vue'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  components: {
    'a-upload': Upload,
    'a-form': Form,
    'a-input': Input,
    'a-button': Button,
    LeftOutlined,
  },
  setup() {
    const fileList = ref([])
    const fileListWeight = ref([])
    const store = useStore()
    const profileData = ref({
      namaDokter: 'John Doe',
      jenisTerapi: 'LYNPARZA',
      email: 'johndoe@example.com',
    })
    const route = useRoute()
    const router = useRouter()
    const handleChange = ({ fileList: newFileList }) => {
      fileList.value = newFileList
    }
    const handleChangeWeight = ({ fileList: newFileList }) => {
      fileListWeight.value = newFileList
    }
    const dataProgramPatient = ref({
      programs: [],
    })
    const handleSubmit = async () => {
      const attribute = 'unggahResepDokter'
      const value = 'unggahResepDokter'
      store.dispatch('updateAttribute', { attribute, value })
      console.log('Submitting files:', fileList.value)
      if (fileList.value.lengths === 0) {
        console.error('No file selected for upload.')
        return
      }

      const file = fileList.value[0].originFileObj // Ambil file yang akan diupload

      try {
        const res = await uploadResepImage('Lab Test Result', file)
        console.log('Upload response:', res)
        let fileWeight = ''
        let resWeight = ''
        if (fileListWeight.value.length) {
          fileWeight = fileListWeight.value[0].originFileObj // Ambil file yang akan diupload
          const res = await uploadWeightImage('Lab Test Result', fileWeight)
          resWeight = res
        }
        const response = await uploadResepResult(
          dataProgramPatient.value.programs[0].id,
          res.data,
          resWeight ? resWeight.data : '',
        )
        console.log(response, 'inihasiltesLabTypeId')
        if (response) {
          router.push(`/survey`)
        }
        // Redirect atau logika tambahan setelah upload sukses
      } catch (error) {
        console.error('Upload failed:', error)
      }
    }
    onMounted(async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await getPatientProgram(userData.user.code)
      dataProgramPatient.value = res
      profileData.value.namaDokter = res.programs[0].doctor.fullname
      profileData.value.jenisTerapi = res.programs[0].programType.name
      console.log(dataProgramPatient.value.programs[0].programType.name, 'inidataprogramdiresep')
    })
    const previewVisible = ref(false)
    const previewImage = ref('')
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }

    return {
      previewVisible,
      previewImage,
      handleCancel,
      handlePreview,
      handleChangeWeight,
      fileListWeight,
      fileList,
      handleChange,
      handleSubmit,
      profileData,
    }
  },
}
</script>

<style scoped>
:global(.ant-upload-list-item-info::before) {
  left: 0 !important;
}
.doctor-prescription {
  /* max-width: 400px; */
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  background: white;
  text-align: center;
}

.doctor-info div {
  margin-bottom: 10px;
  text-align: left;
}

.upload-section {
  /* margin: 20px 0; */
}

.title {
  color: #333;
}

.text-left text-sm-right {
  text-align: left;
  padding-right: 15px;
}

.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.col-12 {
  flex: 1;
}

.col-sm-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-12 col-sm-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
</style>
